<template>
  <b-modal title="Add Account" size="lg" v-model="active">
    <b-row class="form-group">
      <b-col md="6">
        <label>First Name</label>
        <b-form-input type="text" v-model.trim="account.contact.firstName" :state="state($v.account.contact.firstName)" @input="touch($v.account.contact.firstName)"></b-form-input>
        <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
      </b-col>
      <b-col md="6">
        <label>Last Name</label>
        <b-form-input type="text" v-model.trim="account.contact.lastName" :state="state($v.account.contact.lastName)" @input="touch($v.account.contact.lastName)"></b-form-input>
        <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col md="4">
        <label>E-Mail Address</label>
        <b-form-input type="text" placeholder="" v-model.trim="account.contact.emailAddress.address" :state="state($v.account.contact.emailAddress.address)" @input="touch($v.account.contact.emailAddress.address)"></b-form-input>
        <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
      </b-col>
      <b-col md="4">
        <label>Username</label>
        <b-form-input type="text" v-model.trim="account.username" :state="state($v.account.username)" @input="touch($v.account.username)"></b-form-input>
        <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
      </b-col>
      <b-col sm="4">
        <label>Organization</label>
        <b-form-select :options="organizations" v-model="account.organizationId" :disabled="!this.$can('manage', 'clientOrganization')"></b-form-select>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col md="6">
        <b-form-group>
          <b-input-group>
            <b-form-input type="password" autocomplete="current-password" v-model="password" placeholder="Password" :state="state($v.password)" @input="touch($v.password)"></b-form-input>
            <b-input-group-append><b-input-group-text><i class="fa fa-asterisk"></i></b-input-group-text></b-input-group-append>
            <b-form-invalid-feedback>Password needs to be 8 characters</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <b-input-group>
            <b-form-input type="password" autocomplete="current-password" v-model="verifyPassword" placeholder="Verify" :state="state($v.verifyPassword)" @input="touch($v.verifyPassword)"></b-form-input>
            <b-input-group-append><b-input-group-text><i class="fa fa-asterisk"></i></b-input-group-text></b-input-group-append>
            <b-form-invalid-feedback>Passwords should match</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <div slot="modal-footer">
      <b-btn size="sm" class="float-right" :disabled="$v.$invalid" variant="success" @click="save()">
        Add Account
      </b-btn>
    </div>
  </b-modal>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'

export default {
  name: 'AccountModal',
  computed: {
    ...mapGetters({
      currentAccount: 'global/account'
    })
  },
  data () {
    return {
      organizations: [
        { value: null, text: 'Select Organization...', disabled: true }
      ],
      account: {
        contact: {
          firstName: null,
          lastName: null,
          emailAddress: {
            address: null
          }
        },
        username: null,
        roleId: null,
        role: null,
        organizationId: null
      },
      password: null,
      verifyPassword: null,
      active: false
    }
  },
  methods: {
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    show (account) {
      this.active = true
      if (account) {
        this.account = { ...account }
      } else {
        this.account = {
          contact: {
            firstName: null,
            lastName: null,
            emailAddress: {
              address: null
            }
          },
          username: null,
          roleId: null,
          organizationId: this.currentAccount.organizationId || null,
          password: null,
          verifyPassword: null
        }
      }
    },
    save () {
      let account = this.account
      account.password = this.password
      this.$emit('save', { ...account })
      this.active = false
    }
  },
  created () {
    this.$bus.$on('modal:editAccount', (account) => {
      this.show(account);
      this.$nextTick(() => { this.$v.$reset() })
    });

    this.$api.accounts.organizations.get()
      .then((response) => {
        response.data.forEach((organization) => {
          this.organizations.push({ value: organization.id, text: organization.name })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  validations: {
    account: {
      contact: {
        firstName: {
          required
        },
        lastName: {
          required
        },
        emailAddress: {
          address: {
            required
          }
        }
      },
      username: {
        required
      }
    },
    password: {
      required,
      minLength: minLength(8)
    },
    verifyPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  }
}
</script>
