<template>
  <b-modal title="Add Account" size="lg" v-model="active">
    <p>Please contact Mike Crawford at <a href="mailto:mike.crawford@lifesuccesslegacy.com">mike.crawford@lifesuccesslegacy.com</a> to add accounts.</p>
    <div slot="modal-footer">
      <b-btn size="sm" class="float-right" variant="primary" @click="close()">
        Close
      </b-btn>
    </div>
  </b-modal>
</template>

<script>


export default {
  name: 'ContactAdminModal',
  computed: {},
  data () {
    return {
      active: false
    }
  },
  methods: {
    show () {
      this.active = true
    },
    close () {
      this.active = false
    }
  },
  created () {
    this.$bus.$on('modal:contactAdmin', (account) => {
      this.show(account);
    });
  }
}
</script>
