<template>
  <b-row>
    <b-col cols="12" sm="12">
      <b-row class="mb-2" v-if="!$can('manage', 'organizations')">
        <b-col cols="4">
          <b-list-group>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>
                <strong>License Usage:</strong> {{ usedLicenses }} of {{ subscription.numUsers }} users
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" sm="12">
          <transition name="slide">
            <b-card>
              <b-table :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="accounts" :fields="fields" :current-page="currentPage" :per-page="perPage" @row-clicked="rowClicked">
                <template v-slot:cell(roles)="data">
                  <b-badge variant="primary" v-for="role in data.value" v-bind:key="role.id">{{ role.name }}</b-badge>
                  <b-badge variant="danger" v-if="data.value.length === 0">No Roles Assigned</b-badge>
                </template>
              </b-table>
              <nav>
                <b-row>
                  <b-col sm="6">
                    <b-pagination size="sm" :total-rows="getRowCount(accounts)" :per-page="perPage" v-model="currentPage" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
                  </b-col>
                  <b-col sm="6" class="text-right">
                    <b-button :disabled="!canAddAccount && !$can('manage', 'organizations')" size="sm" variant="success" @click="addAccount"><i class="icon-plus"></i> Add New Account</b-button>
                  </b-col>
                </b-row>
              </nav>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <account-modal @save="create"></account-modal>
      <contact-admin-modal></contact-admin-modal>
    </b-col>
  </b-row>
</template>

<script>
import AccountModal from './modals/Account'
import ContactAdminModal from './modals/ContactAdmin'
import { mapGetters } from 'vuex'

export default {
  name: 'Accounts',
  components: {
    AccountModal,
    ContactAdminModal
  },
  props: {
    caption: {
      type: String,
      default: 'Accounts'
    },
    hover: {
      type: Boolean,
      default: true
    },
    striped: {
      type: Boolean,
      default: true
    },
    bordered: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: true
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      accounts: [],
      fields: [
        { key: 'contact.firstName', label: 'First Name' },
        { key: 'contact.lastName', label: 'Last Name' },
        { key: 'username' },
        { key: 'contact.emailAddress.address', label: 'Email Address' },
        { key: 'organization.name', label: 'Organization' },
        { key: 'roles', label: 'Roles' }
      ],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      subscription: {
        numUsers: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      currentAccount: 'global/account'
    }),
    usedLicenses () {
      if (this.currentAccount) {
        const currentAccounts = this.accounts.reduce((sum, account) => {
          return sum += (account.organizationId == this.currentAccount.organizationId)

        }, 0)
        return currentAccounts
      }
      return 0
    },
    canAddAccount () {
      if (this.subscription && this.usedLicenses >= this.subscription.numUsers) {
        return false
      }
      return true
    }
  },
  methods: {
    fetchAccounts () {
      this.$api.accounts.get()
      .then((response) => {
        this.accounts = response.data
      })
      .catch((err) => {
        this.$snotify.error('Error Fetching Accounts')
      })
    },
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getRowCount (items) {
      return items.length
    },
    userLink (id) {
      return `${id.toString()}`
    },
    rowClicked (item) {
      const userLink = this.userLink(item.id)
      this.$router.push({path: userLink})
    },
    addAccount () {
      if (this.$can('create', 'account')) {
        this.$bus.$emit('modal:editAccount')
      } else {
        this.$bus.$emit('modal:contactAdmin')
      }
    },
    create (account) {
      this.$api.accounts.create(account)
        .then((response) => {
          this.$snotify.success('Account Created Successfully')
          const userLink = this.userLink(response.data.id)
          this.$router.push({path: userLink})
        })
        .catch((err) => {
          this.$snotify.error('Error Fetching Accounts')
        })
    },
    fetchSubscription () {
      if (this.currentAccount) {
        this.$api.payments.subscriptions.get({ organizationId: this.currentAccount.organizationId }).then((response) => {
          this.subscription = response.data.find(o => o.isActive)
        })
      }
    }
  },
  created () {
    this.fetchAccounts()
    this.fetchSubscription()
  },
  watch: {
    currentAccount: {
      handler: function () {
        this.fetchSubscription()
      },
      deep: true
    }
  },
}
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}

.badge {
  margin-left: 4px;
}
.badge:first-child {
  margin-left: 0px;
}
</style>
